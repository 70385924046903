<template>
	<div class="hot-sale clearfix">
		<div class="title">热销榜</div>
		<ul>
			<li class="clearfix" v-for="(item,index) in hotSaleList.data" :key="index" v-if="index < 5">
				<router-link :to="{ path: '/details', query: { id: item.goods_id }}">
					<img :src="item.goods_image" />
					<p class="product-title line2">{{item.goods_name}}</p>
					<p>
						<span class="price">￥{{item.goods_price_min}}</span>
						<span class="sales-volume">热销{{item.goods_sales}}件</span>
					</p>
					<div class="icon">{{index+1}}</div>
				</router-link>
			</li>
		</ul>
	</div>
</template>

<script>
	import * as GoodsApi from '@/api/goods'
	export default {
		data() {
			return {
				hotSaleList: []
			}
		},
		created() {
			this.getList()
		},
		methods: {
			getList() {
				let app = this
				const param = {
					sortType: 'sales',
					sortPrice: 0,
					categoryId: 0,
					goodsName: '',
					page: 1
				}

				GoodsApi.list(param).then(result => {
					// 合并新数据
					const newList = result.data.list
					app.hotSaleList = newList
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	// 热销榜
	.hot-sale {
		float: left;
		width: 268px;
		border: 1px solid $main-solid-color;
		color: $main-text-color-light;

		.title {
			font-size: 15px;
			font-weight: bold;
			padding: 10px 20px;
			background-color: $main-bg-color;
			text-align: center;
		}

		ul {
			li {
				width: 220px;
				margin: 20px;
				text-align: center;
				position: relative;

				.icon {
					position: absolute;
					top: 0;
					left: 0px;
					width: 24px;
					height: 24px;
					line-height: 24px;
					border-radius: 0px 0px 12px 0px;
					font-size: 12px;
					text-align: center;
					font-weight: bold;
					background: $main-color;
					color: #FFF;
				}

				img {
					width: 220px;
					height: 220px;
					border: 1px solid $main-solid-color;
				}

				p {
					text-align: left;
					margin: 5px 0 0 0;
					line-height: 20px;
					color: $main-text-color-light;
					&.product-title{
						margin:5px 0 15px 0;
					}

					.price {
						float: left;
						font-size: 16px;
						color: $main-red-color;
						font-weight: bold;
					}

					.sales-volume {
						float: right;
						font-size: 12px;
					}
				}
			}
		}
	}
</style>